import {currentPatch} from '~/components/PatchSelector';
import {invert} from 'lodash';

export const minItemLevel = 290;
export const defaultMinItemLevel = 730;
export const defaultCraftGatherMinItemLevel = 620;
export const maxItemLevel = 765;
export const battleFoodItemLevelRange = {min: 370, max: maxItemLevel};
export const craftGatherFoodItemLevelRange = {min: 265, max: maxItemLevel};
export const foodItemLevelRange = {
  minItemLevel: craftGatherFoodItemLevelRange.min,
  maxItemLevel: battleFoodItemLevelRange.max
};
export const craftMedicineItemLevelRange = {minItemLevel: 273};
export const baseGCD = 2500;
export const bluMaxLevel = 80;
export const defaultMaxLevel = 100;
export const defaultBLUMinItemLevel = 510;
export const defaultBLUMaxItemLevel = 530;
export const defaultBLUMaxMateriaTier = 8;
export const hqPotCooldownDeltaS = 30;
export const hqIcon = '\uE03C';
export const iLevelSyncIcon = '\uE0BC';
export const defaultMateriaRange = [11, 12];
export {currentPatch};
export const ORDER_KEY = [
  ['itemLevel', 'name'],
  ['desc', 'asc']
];

// Used to get Param objects for max meld calculations
// PIE, TEN, DH, CRT, DET, SKS, SPS
// Also used in update_equipment
export const meldableParamIds = [
  6,
  19,
  22,
  27,
  44,
  45,
  46,
  10,
  11,
  70,
  71,
  72,
  73
];

export const jobList = [
  'AST',
  'BLM',
  'BLU',
  'BRD',
  'DNC',
  'DRG',
  'DRK',
  'GNB',
  'MCH',
  'MNK',
  'NIN',
  'PCT',
  'PLD',
  'RDM',
  'RPR',
  'SAM',
  'SCH',
  'SGE',
  'SMN',
  'VPR',
  'WAR',
  'WHM',
  'ALC',
  'ARM',
  'BSM',
  'BTN',
  'CRP',
  'CUL',
  'FSH',
  'GSM',
  'LTW',
  'MIN',
  'WVR'
];

export const tankJobList = ['DRK', 'GNB', 'PLD', 'WAR'];
export const healerJobList = ['AST', 'SCH', 'SGE', 'WHM'];
export const casterJobList = ['BLM', 'BLU', 'PCT', 'RDM', 'SMN'];
export const dexJobList = ['BRD', 'DNC', 'MCH', 'NIN', 'VPR'];
export const strJobList = ['DRG', 'MNK', 'RPR', 'SAM'];
export const similarJobs = [
  tankJobList,
  healerJobList,
  casterJobList,
  dexJobList,
  strJobList
];

export const getSimilarJobs = jobAbbrev => {
  switch (jobAbbrev) {
    case 'DRK':
    case 'GNB':
    case 'PLD':
    case 'WAR':
      return tankJobList;
    case 'AST':
    case 'SCH':
    case 'SGE':
    case 'WHM':
      return healerJobList;
    case 'BLM':
    case 'BLU':
    case 'PCT':
    case 'RDM':
    case 'SMN':
      return casterJobList;
    case 'BRD':
    case 'DNC':
    case 'MCH':
    case 'NIN':
    case 'VPR':
      return dexJobList;
    case 'DRG':
    case 'MNK':
    case 'RPR':
    case 'SAM':
      return strJobList;
    default:
      return [];
  }
};

const baseSlotObjects = [
  {name: 'Head', equipKey: 'head', id: 3},
  {name: 'Body', equipKey: 'body', id: 4},
  {name: 'Hands', equipKey: 'hands', id: 5},
  {name: 'Legs', equipKey: 'legs', id: 7},
  {name: 'Feet', equipKey: 'feet', id: 8},
  {name: 'Earrings', equipKey: 'ears', id: 9},
  {name: 'Necklace', equipKey: 'neck', id: 10},
  {name: 'Bracelets', equipKey: 'wrists', id: 11},
  {name: 'Left Ring', equipKey: 'finger', gearsetKey: 'fingerL', id: 12},
  {name: 'Right Ring', equipKey: 'finger', gearsetKey: 'fingerR', id: 12},
  {name: 'Food', gearsetKey: 'food'}
];

const weaponSlotObject = {name: 'Weapon', equipKey: 'weapon', id: 13},
  primaryToolSlotObject = {name: 'Primary Tool', equipKey: 'weapon', id: 13},
  secondaryToolSlotObject = {
    name: 'Secondary Tool',
    equipKey: 'offHand',
    id: 2
  };

// Name: Gearset Card title, equipKey: Key for data in JSON, gearsetKey: key for gearset state and API
export const battleSlotObjects = [weaponSlotObject, ...baseSlotObjects];

export const pldSlotObjects = [
  weaponSlotObject,
  {name: 'Shield', equipKey: 'offHand', id: 2},
  ...baseSlotObjects
];

export const craftSlotObjects = [
  primaryToolSlotObject,
  secondaryToolSlotObject,
  ...baseSlotObjects,
  {name: 'Medicine', gearsetKey: 'medicine'},
  {name: 'Soul Crystal & FC Actions', gearsetKey: 'buffs'}
];

export const gatherSlotObjects = [
  primaryToolSlotObject,
  secondaryToolSlotObject,
  ...baseSlotObjects,
  {name: 'FC Actions', gearsetKey: 'buffs'}
];

// Used by onFilterItemLevel. Use PLD because it has all keys needed.
export const gearsetSlotKeys = pldSlotObjects.reduce(
  (r, {equipKey, gearsetKey}) => {
    if (gearsetKey !== 'food') {
      r.push(!!gearsetKey ? gearsetKey : equipKey);
    }

    return r;
  },
  []
);

export const paramNames = {
  1: 'STR',
  2: 'DEX',
  3: 'VIT',
  4: 'INT',
  5: 'MND',
  6: 'PIE',
  7: 'HP',
  8: 'MP',
  9: 'TP',
  12: 'DMG',
  13: 'DMG',
  19: 'TEN',
  21: 'DEF',
  22: 'DH',
  24: 'MDEF',
  27: 'CRT',
  44: 'DET',
  45: 'SKS',
  46: 'SPS',
  10: 'GP',
  11: 'CP',
  70: 'CRFT',
  71: 'CNTL',
  72: 'GATH',
  73: 'PERC'
};

export const physDamageId = 12;
export const magDamageId = 13;

export const paramIds = invert(paramNames);

export const crafterParamIds = [11, 70, 71];
export const gatherParamIds = [10, 72, 73];

export const paramSortOrder = {
  12: 1, // Phys DMG
  13: 1, // Mag DMG
  1: 2, // STR
  2: 3, // DEX
  4: 4, // INT
  5: 5, // MND
  3: 6, // VIT
  21: 6, // DEF
  24: 6, // MDEF
  6: 7, // PIE
  22: 8, // DH
  27: 9, // CRT
  44: 10, // DET
  19: 11, // TEN
  45: 12, // SKS
  46: 13, // SPS
  10: 16, //GP
  11: 16, // CP
  70: 14, // CRFT
  71: 15, // CNTL
  72: 14, // GATH
  73: 15 // PERC
};

export const baseParams = {
  50: {
    STR: 202,
    DEX: 202,
    VIT: 202,
    INT: 202,
    MND: 202,
    PIE: 202,
    HP: 1400,
    hpScalar: 11.2,
    hpScalarTank: 15.5,
    MP: 10000,
    mpRefresh: 200,
    TEN: 341,
    DH: 341,
    CRT: 341,
    DET: 202,
    SKS: 341,
    SPS: 341,
    CRFT: 0,
    CNTL: 0,
    CP: 180,
    GATH: 0,
    PERC: 0,
    GP: 400,
    levelDiv: 341,
    DEF: 0,
    MDEF: 0,
    mainScalar: 75,
    mainScalarTank: 85
  },
  60: {
    STR: 218,
    DEX: 218,
    VIT: 218,
    INT: 218,
    MND: 218,
    PIE: 218,
    HP: 1500,
    hpScalar: 12.9,
    hpScalarTank: 17.5,
    MP: 10000,
    mpRefresh: 200,
    TEN: 354,
    DH: 354,
    CRT: 354,
    DET: 218,
    SKS: 354,
    SPS: 354,
    CRFT: 0,
    CNTL: 0,
    CP: 180,
    GATH: 0,
    PERC: 0,
    GP: 400,
    levelDiv: 600,
    DEF: 0,
    MDEF: 0,
    mainScalar: 100,
    mainScalarTank: 95
  },
  70: {
    STR: 292,
    DEX: 292,
    VIT: 292,
    INT: 292,
    MND: 292,
    PIE: 292,
    HP: 1700,
    hpScalar: 14,
    hpScalarTank: 18.8,
    MP: 10000,
    mpRefresh: 200,
    TEN: 364,
    DH: 364,
    CRT: 364,
    DET: 292,
    SKS: 364,
    SPS: 364,
    CRFT: 0,
    CNTL: 0,
    CP: 180,
    GATH: 0,
    PERC: 0,
    GP: 400,
    levelDiv: 900,
    DEF: 0,
    MDEF: 0,
    mainScalar: 125,
    mainScalarTank: 105
  },
  80: {
    STR: 340,
    DEX: 340,
    VIT: 340,
    INT: 340,
    MND: 340,
    PIE: 340,
    HP: 2000,
    hpScalar: 18.8,
    hpScalarTank: 26.6,
    MP: 10000,
    mpRefresh: 200,
    TEN: 380,
    DH: 380,
    CRT: 380,
    DET: 340,
    SKS: 380,
    SPS: 380,
    CRFT: 0,
    CNTL: 0,
    CP: 180,
    GATH: 0,
    PERC: 0,
    GP: 400,
    levelDiv: 1300,
    DEF: 0,
    MDEF: 0,
    mainScalar: 165,
    mainScalarTank: 115
  },
  90: {
    STR: 390,
    DEX: 390,
    VIT: 390,
    INT: 390,
    MND: 390,
    PIE: 390,
    HP: 3000,
    hpScalar: 24.3,
    hpScalarTank: 34.6,
    MP: 10000,
    mpRefresh: 200,
    TEN: 400,
    DH: 400,
    CRT: 400,
    DET: 390,
    SKS: 400,
    SPS: 400,
    CRFT: 0,
    CNTL: 0,
    CP: 180,
    GATH: 0,
    PERC: 0,
    GP: 400,
    levelDiv: 1900,
    DEF: 0,
    MDEF: 0,
    mainScalar: 195,
    mainScalarTank: 156
  },
  100: {
    STR: 440,
    DEX: 440,
    VIT: 440,
    INT: 440,
    MND: 440,
    PIE: 440,
    HP: 4000,
    hpScalar: 30.1,
    hpScalarTank: 43,
    MP: 10000,
    mpRefresh: 200,
    TEN: 420,
    DH: 420,
    CRT: 420,
    DET: 440,
    SKS: 420,
    SPS: 420,
    CRFT: 0,
    CNTL: 0,
    CP: 180,
    GATH: 0,
    PERC: 0,
    GP: 400,
    levelDiv: 2780,
    DEF: 0,
    MDEF: 0,
    mainScalar: 237,
    mainScalarTank: 190
  }
};

export const baseParamLevels = Object.keys(baseParams).map(Number);
export const mainParamNameList = ['STR', 'DEX', 'VIT', 'INT', 'MND'];
export const mainParamNameListNoVIT = mainParamNameList.filter(
  x => x !== 'VIT'
);
export const secondaryParamNameList = ['DH', 'CRT', 'DET', 'DEF', 'MDEF'];

export const jobParamList = {
  AST: {
    main: ['MND', 'VIT'],
    secondary: ['PIE', 'SPS'],
    damageTrait: 1.3
  },
  BLM: {
    main: ['INT', 'VIT'],
    secondary: ['SPS'],
    gcdMod: 15,
    gcdModName: 'LL',
    gcdModNameLong: 'Ley Lines',
    damageTrait: 1.3
  },
  BLU: {
    main: ['INT', 'VIT'],
    secondary: ['SPS'],
    damageTrait: 1.5
  },
  BRD: {
    main: ['DEX', 'VIT'],
    secondary: ['SKS'],
    gcdMod: 16,
    gcdModName: 'AP',
    gcdModNameLong: "Army's Paeon IV",
    damageTrait: 1.2
  },
  DNC: {main: ['DEX', 'VIT'], secondary: ['SKS'], damageTrait: 1.2},
  DRG: {main: ['STR', 'VIT'], secondary: ['SKS'], damageTrait: 1},
  DRK: {main: ['STR', 'VIT'], secondary: ['TEN', 'SKS'], damageTrait: 1},
  GNB: {main: ['STR', 'VIT'], secondary: ['TEN', 'SKS'], damageTrait: 1},
  MCH: {main: ['DEX', 'VIT'], secondary: ['SKS'], damageTrait: 1.2},
  MNK: {
    main: ['STR', 'VIT'],
    secondary: ['SKS'],
    gcdMod: {50: 15, 60: 15, 70: 15, 80: 20, 90: 20, 100: 20},
    gcdModName: 'GL',
    gcdModNameLong: 'Greased Lightning',
    damageTrait: 1
  },
  NIN: {
    main: ['DEX', 'VIT'],
    secondary: ['SKS'],
    gcdMod: 15,
    gcdModName: 'Huton',
    damageTrait: 1
  },
  PCT: {
    main: ['INT', 'VIT'],
    secondary: ['SPS'],
    gcdMod: 25,
    gcdModName: 'SM',
    gcdModNameLong: 'Starry Muse',
    damageTrait: 1.3
  },
  PLD: {main: ['STR', 'VIT'], secondary: ['TEN', 'SKS'], damageTrait: 1},
  RDM: {main: ['INT', 'VIT'], secondary: ['SPS'], damageTrait: 1.3},
  RPR: {main: ['STR', 'VIT'], secondary: ['SKS'], damageTrait: 1},
  SAM: {
    main: ['STR', 'VIT'],
    secondary: ['SKS'],
    gcdMod: {50: 10, 60: 10, 70: 10, 80: 13, 90: 13, 100: 13},
    gcdModName: 'Fuka',
    damageTrait: 1
  },
  SCH: {main: ['MND', 'VIT'], secondary: ['PIE', 'SPS'], damageTrait: 1.3},
  SGE: {main: ['MND', 'VIT'], secondary: ['PIE', 'SPS'], damageTrait: 1.3},
  SMN: {main: ['INT', 'VIT'], secondary: ['SPS'], damageTrait: 1.3},
  VPR: {
    main: ['DEX', 'VIT'],
    secondary: ['SKS'],
    gcdMod: 15,
    gcdModName: 'SS',
    gcdModNameLong: 'Swiftscaled',
    damageTrait: 1
  },
  WAR: {main: ['STR', 'VIT'], secondary: ['TEN', 'SKS'], damageTrait: 1},
  WHM: {
    main: ['MND', 'VIT'],
    secondary: ['PIE', 'SPS'],
    gcdMod: 20,
    gcdModName: 'PoM',
    gcdModNameLong: 'Presence of Mind',
    damageTrait: 1.3
  },
  ALC: {main: [], secondary: ['CRFT', 'CNTL', 'CP']},
  ARM: {main: [], secondary: ['CRFT', 'CNTL', 'CP']},
  BSM: {main: [], secondary: ['CRFT', 'CNTL', 'CP']},
  CRP: {main: [], secondary: ['CRFT', 'CNTL', 'CP']},
  CUL: {main: [], secondary: ['CRFT', 'CNTL', 'CP']},
  GSM: {main: [], secondary: ['CRFT', 'CNTL', 'CP']},
  LTW: {main: [], secondary: ['CRFT', 'CNTL', 'CP']},
  WVR: {main: [], secondary: ['CRFT', 'CNTL', 'CP']},
  BTN: {main: [], secondary: ['GATH', 'PERC', 'GP']},
  FSH: {main: [], secondary: ['GATH', 'PERC', 'GP']},
  MIN: {main: [], secondary: ['GATH', 'PERC', 'GP']}
};

/*
  10: 'GP',
  11: 'CP',
  70: 'CRFT',
  71: 'CNTL',
  72: 'GATH',
  73: 'PERC'
*/

export const buffTiers = [
  {label: 'I', value: '5'},
  {label: 'II', value: '10'},
  {label: 'III', value: '15'}
];

export const crafterBuffs = [
  {
    label: 'Eat from the Hand',
    icon: '/i/216000/216504.png',
    key: 'eatFromTheHand'
  },
  {label: 'In Control', icon: '/i/216000/216505.png', key: 'inControl'}
];

export const specialist = {
  label: 'Specialist',
  icon: '/i/026000/026051.png',
  key: 'specialist'
};

// Buffs are separate above because crafterBuffs is mapped over to create Radio
export const fullCrafterBuffs = [specialist].concat(crafterBuffs);

export const crafterBuffParams = {
  specialist: {11: 15, 70: 20, 71: 20},
  inControl: {0: {}, 5: {71: 5}, 10: {71: 10}, 15: {71: 15}},
  eatFromTheHand: {0: {}, 5: {70: 5}, 10: {70: 10}, 15: {70: 15}}
};

export const gathererBuffs = [
  {
    label: 'Live off the Land',
    icon: '/i/216000/216502.png',
    key: 'liveOffTheLand'
  },
  {label: 'What You See', icon: '/i/216000/216503.png', key: 'whatYouSee'}
];

export const gathererBuffParams = {
  liveOffTheLand: {0: {}, 5: {72: 5}, 10: {72: 10}, 15: {72: 15}},
  whatYouSee: {0: {}, 5: {73: 5}, 10: {73: 10}, 15: {73: 15}}
};

export const specialEquipment = {
  BLU: [22746, 24551, 24796, 28492, 32644, 32645, 40345, 40476],
  FSH: [17726]
};

// exd MateriaGrade
// Only HQ overmeld rates
// {MateriaTier: {[overmeld slotNum]: rate}}
export const materiaMeldRate = {
  1: {1: 90, 2: 48, 3: 28, 4: 16},
  2: {1: 82, 2: 44, 3: 26, 4: 16},
  3: {1: 70, 2: 38, 3: 22, 4: 14},
  4: {1: 58, 2: 32, 3: 20, 4: 12},
  5: {1: 17, 2: 10, 3: 7, 4: 5},
  6: {1: 17, 2: 0, 3: 0, 4: 0},
  7: {1: 17, 2: 10, 3: 7, 4: 5},
  8: {1: 17, 2: 0, 3: 0, 4: 0},
  9: {1: 17, 2: 10, 3: 7, 4: 5},
  10: {1: 17, 2: 0, 3: 0, 4: 0},
  11: {1: 17, 2: 10, 3: 7, 4: 5},
  12: {1: 17, 2: 0, 3: 0, 4: 0}
};

export const partyBonus = {
  Off: 1,
  '3%': 1.03,
  '4%': 1.04,
  '5%': 1.05
};

export const partyBonusOptions = Object.entries(partyBonus).map(
  ([key, value]) => ({
    label: key,
    value
  })
);
